import react, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBalance } from '../redux/user.slice';
import {
  setJackpots,
  setRecentWinners,
  setRecentWithdraws,
} from '../redux/recent.slice';
import signalRSingleton from '../singletons/signalR.singleton';
import globalEmitter, { emits } from '../emitter/global.emitter';

function SocketWrapper() {
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.user);

  const userHubConnected = useRef(false);
  const slotHubConnected = useRef(false);

  const MessageTypes = {
    0: 'error',
    1: 'success',
    2: 'warning',
    3: 'info',
  };

  function watchUserHubEvents() {
    if (signalRSingleton.userHubConnected) {
      signalRSingleton.userHubConnection.on('Balance', (message) => {
        dispatch(setBalance(message));
      });
      signalRSingleton.userHubConnection.on('Message', (data) => {
        globalEmitter.emit(emits.ALERT, {
          type: MessageTypes[data?.type] || MessageTypes[3],
          message: data?.msg || '',
        });
      });
    }
  }

  function watchSlotHubEvents() {
    if (signalRSingleton.slotHubConnected) {
      signalRSingleton.slotHubConnection.on(
        'RecentWinnersResult',
        (message) => {
          if (message && message.length) {
            dispatch(setRecentWinners(message));
          }
        }
      );

      signalRSingleton.slotHubConnection.on(
        'RecentWithdrawResult',
        (message) => {
          if (message && message.length) {
            dispatch(setRecentWithdraws(message));
          }
        }
      );

      signalRSingleton.slotHubConnection.on('Jackpot', (message) => {
        try {
          dispatch(setJackpots(JSON.parse(message)));
        } catch (e) {
          console.error('WrapperSocket -> Jackpot parse Error:', e);
        }
      });
    }
  }

  async function connectToUserHub() {
    try {
      await signalRSingleton.connectToUserHub();
      watchUserHubEvents();
    } catch (e) {
      console.error('UserHub Connection Error: ', e);
    }
  }

  async function connectToSlotHub() {
    try {
      await signalRSingleton.connectToSlotHub();
      watchSlotHubEvents();
    } catch (e) {
      console.error('SlotHub Connection Error: ', e);
    }
  }

  useEffect(() => {
    if (id && !userHubConnected.current) {
      connectToUserHub();
      userHubConnected.current = true;
    } else if (!id && userHubConnected.current) {
      signalRSingleton.disconnectFromUserHub();
      userHubConnected.current = false;
    }
  }, [id, userHubConnected.current]);

  useEffect(() => {
    if (!slotHubConnected.current) {
      connectToSlotHub();
      slotHubConnected.current = true;
    }
  }, []);

  return <></>;
}

export default react.memo(SocketWrapper);
